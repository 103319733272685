import React from 'react'
import NavBar from '../components/common/NavBar'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Component from '../components/common/Component'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

export default function Terms(): JSX.Element {
  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <NavBar />
      <Container>
        <div
          style={{
            padding: '1em',
            paddingTop: '2em',
          }}
        >
          <Component>
            <Grid container justifyContent="center" spacing={3} py={3} px={2}>
              <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>
                  Terms of Service
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  gutterBottom
                >
                  Effective Date: 08/01/2021
                </Typography>

                <Typography variant="body1" paragraph>
                  Welcome to Team Automate LLC (“we,” “us,” or “our”). By
                  accessing or using our website and services, you agree to
                  comply with and be bound by these Terms of Service (“Terms”).
                  If you do not agree to these Terms, please do not use our
                  website or services.
                </Typography>

                <Box sx={{ mt: 3 }}>
                  <Typography variant="h5" gutterBottom>
                    Description of Services
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Team Automate LLC provides automation services tailored to
                    the needs of businesses. We automate specific tasks on
                    websites or platforms provided by you (“the Client”). While
                    we strive for high accuracy and reliability, we do not
                    guarantee 100% success in all automation tasks.
                  </Typography>
                </Box>

                <Box sx={{ mt: 3 }}>
                  <Typography variant="h5" gutterBottom>
                    User Responsibilities
                  </Typography>
                  <Typography variant="body1" component="div">
                    <ul>
                      <li>
                        <strong>Access and Authorization:</strong> You are
                        responsible for providing lawful access to the websites
                        or platforms for automation. By granting access, you
                        confirm you have the right and authority to do so.
                      </li>
                      <li>
                        <strong>Accuracy of Information:</strong> You must
                        provide accurate and complete information regarding the
                        tasks you wish to automate. Any omissions or errors may
                        impact the effectiveness of our services.
                      </li>
                      <li>
                        <strong>Compliance with Laws:</strong> You agree that
                        your use of our services complies with all applicable
                        laws, regulations, and the terms of service of the
                        third-party websites/platforms involved.
                      </li>
                      <li>
                        <strong>Discretion in Use:</strong> You acknowledge that
                        using our services is at your own discretion. Team
                        Automate LLC will not be liable for any unintended
                        consequences arising from the use of our services.
                      </li>
                    </ul>
                  </Typography>
                </Box>

                <Box sx={{ mt: 3 }}>
                  <Typography variant="h5" gutterBottom>
                    Limitations of Liability
                  </Typography>
                  <Typography variant="body1" component="div">
                    <ul>
                      <li>
                        <strong>No Guarantees:</strong> While we strive to
                        provide accurate and efficient automation, we do not
                        guarantee error-free or uninterrupted service.
                        Automation may fail due to website changes, technical
                        issues, or other unforeseen circumstances.
                      </li>
                      <li>
                        <strong>Indemnity:</strong> You agree to indemnify and
                        hold Team Automate LLC harmless from any claims, losses,
                        or damages arising from your use of our services,
                        including violations of third-party terms or
                        unauthorized access.
                      </li>
                      <li>
                        <strong>Third-Party Interactions:</strong> We are not
                        responsible for any changes, updates, or actions taken
                        by third-party websites or platforms that affect
                        automation performance.
                      </li>
                    </ul>
                  </Typography>
                </Box>

                <Box sx={{ mt: 3 }}>
                  <Typography variant="h5" gutterBottom>
                    Prohibited Uses
                  </Typography>
                  <Typography variant="body1" component="div">
                    <ul>
                      <li>Illegal or unauthorized purposes.</li>
                      <li>
                        Accessing or automating platforms without the necessary
                        permissions.
                      </li>
                      <li>Violating any third-party terms of service.</li>
                    </ul>
                  </Typography>
                </Box>

                <Box sx={{ mt: 3 }}>
                  <Typography variant="h5" gutterBottom>
                    Intellectual Property
                  </Typography>
                  <Typography variant="body1" paragraph>
                    All content, software, and materials on our website are the
                    property of Team Automate LLC or our licensors. You may not
                    copy, distribute, or use them without prior written consent.
                  </Typography>
                </Box>

                <Box sx={{ mt: 3 }}>
                  <Typography variant="h5" gutterBottom>
                    Modifications to Services and Terms
                  </Typography>
                  <Typography variant="body1" paragraph>
                    We reserve the right to update or modify these Terms at any
                    time. Changes will take effect immediately upon posting.
                    Continued use of our services constitutes your acceptance of
                    the updated Terms.
                  </Typography>
                </Box>

                <Box sx={{ mt: 3 }}>
                  <Typography variant="h5" gutterBottom>
                    Disclaimer of Warranties
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Our services are provided “as is” and “as available.” We
                    disclaim all warranties, express or implied, including but
                    not limited to implied warranties of merchantability,
                    fitness for a particular purpose, and non-infringement.
                  </Typography>
                </Box>

                <Box sx={{ mt: 3 }}>
                  <Typography variant="h5" gutterBottom>
                    Governing Law
                  </Typography>
                  <Typography variant="body1" paragraph>
                    These Terms are governed by and construed in accordance with
                    the laws of North Carolina, without regard to conflict of
                    law principles.
                  </Typography>
                </Box>

                <Box sx={{ mt: 3, mb: 5 }}>
                  <Typography variant="h5" gutterBottom>
                    Contact Us
                  </Typography>
                  <Typography variant="body1" paragraph>
                    For questions about these Terms or our services, please
                    contact us at:
                  </Typography>
                  <Typography variant="body1" paragraph>
                    <strong>Team Automate LLC</strong>
                    <br />
                    Email:{' '}
                    <a href="mailto:info@teamautomate.com">
                      info@teamautomate.com
                    </a>
                  </Typography>
                </Box>

                <Typography variant="body1" paragraph>
                  By using our website or services, you confirm that you have
                  read, understood, and agreed to these Terms of Service.
                </Typography>
              </Grid>
            </Grid>
          </Component>
        </div>
      </Container>
    </div>
  )
}
