import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { Link, useHistory } from 'react-router-dom'
import Component from '../../components/common/Component'
import { isSuperAdmin, sha256 } from '../../utils/functions'
import Alert, { AlertInput } from '../../components/common/Alert'
import { isValidEmail } from '.'
import { login } from '../../api/account'
import { useContext } from 'react'
import AppContext, { JWT } from '../../app/AppContext'
import jwt from 'jsonwebtoken'

export default function Login(): JSX.Element {
  const redirectTo =
    new URLSearchParams(window.location.search).get('redirectTo') || undefined

  const history = useHistory()
  const { appReducer } = useContext(AppContext)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const handleSubmit = async () => {
    const hashPass = await sha256(password)

    // check email and password again
    if (!isValidEmail(email)) {
      setAlert({
        open: true,
        severity: 'error',
        message: 'Invalid email.',
      })
    } else {
      const result = await login(email, hashPass)

      if (result.success) {
        const jwtData = jwt.decode(result.data || '') as JWT
        appReducer({ type: 'jwt', payload: jwtData })

        // If user has multiple clients, redir to select
        if (jwtData.clientIds?.length || isSuperAdmin(jwtData.roleId)) {
          history.push(
            '/selectClient' + (redirectTo ? `?redirectTo=${redirectTo}` : ''),
          )
          // else log in to dashboard
        } else {
          history.push(redirectTo || '/dashboard')
        }
      } else {
        setAlert({
          open: true,
          severity: 'error',
          message: result.message,
        })
      }
    }
  }

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          sx={{
            marginTop: '3em',
          }}
        >
          <Component>
            <Typography variant="h4" component="h1" color="primary">
              Team Automate
            </Typography>
            <form
              onSubmit={async (e) => {
                e.preventDefault()
                await handleSubmit()
              }}
            >
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12} mt={6}>
                  <TextField
                    label="Email"
                    type="text"
                    size="small"
                    variant="outlined"
                    fullWidth={true}
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Password"
                    type="password"
                    size="small"
                    variant="outlined"
                    value={password}
                    fullWidth={true}
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  ></TextField>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="caption">
                    By using Team Automate you agree to our{' '}
                    <Link to="/terms">Terms of Service</Link>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Link to="/createAccount" style={{ fontSize: 14 }}>
                    Create Account
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  <Link to="/forgotPassword" style={{ fontSize: 14 }}>
                    Forgot Password
                  </Link>
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  type="submit"
                >
                  Submit
                </Button>
              </Grid>
            </form>
          </Component>
        </Grid>
      </Grid>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      />
    </>
  )
}
